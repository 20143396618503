import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import {SeoHeadQuery} from '../../types/graphql-types';

interface Props {
  description?: string,
  lang?: string,
  meta?: React.DetailedHTMLProps<React.MetaHTMLAttributes<HTMLMetaElement>, HTMLMetaElement>[],
  title: string,
}
const Index: React.FC<Props> = ({ description = '', lang = 'en', meta = [], title }) => {
  const { site } = useStaticQuery<SeoHeadQuery>(
    graphql`
      query SEOHead {
        site {
          siteMetadata {
            title
            description
            author {
              name
            }
          }
        }
      }
    `,
  );

  const metaDescription = description || site?.siteMetadata?.description || '';

  const metaArr: any = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: site?.siteMetadata?.author||'',
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site?.siteMetadata?.title??''}`}
      meta={metaArr.concat(meta)}
    />
  );
}

export default Index;
