import * as React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './styled.components';

/**
 * Box is the simplest element for building complete themes.
 */

type Props = { children: React.ReactNode; flexBox: boolean };
export type Ref = HTMLElement;

const Box: React.ForwardRefExoticComponent<Props> = 
React.forwardRef<Ref, Props>(({ children, ...props }, ref) => (
  <Wrapper ref={ref} {...props}>
    {children}
  </Wrapper>
));

export default Box;
