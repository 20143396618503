import * as Yup from 'yup';

import MapMarker from './icons/line/MapMarker';
import Phone from './icons/line/Phone';
import Globe from './icons/line/Globe';
import Envelope from './icons/line/Envelope';
import FacebookF from './icons/line/FacebookF';
import Instagram from './icons/line/Instagram';
import Youtube from './icons/line/Youtube';
import Dna from './icons/line/Dna';
import Flask from './icons/line/Flask';
import Atom from './icons/line/Atom';
import CheckCircle from './icons/line/CheckCircle';
import Gift from './icons/line/Gift';
import Coffee from './icons/line/Coffee';
import Medal from './icons/line/Medal';
import LightbulbAlt from './icons/line/LightbulbAlt';

import textToMultiline from './common/utils/textToMultiline';

import firstFuture from './assets/frist-feature.svg';
import secondFuture from './assets/second-feature.svg';
import thirdFuture from './assets/third-feature.svg';

import Phone1 from './assets/screenshots/Phone-1.png';
import Phone1x2 from './assets/screenshots/Phone-1@2x.png';
import Phone2 from './assets/screenshots/Phone-2.png';
import Phone2x2 from './assets/screenshots/Phone-2@2x.png';
import Phone3 from './assets/screenshots/Phone-3.png';
import Phone3x2 from './assets/screenshots/Phone-3@2x.png';
import Phone4 from './assets/screenshots/Phone-4.png';
import Phone4x2 from './assets/screenshots/Phone-4@2x.png';
import Phone5 from './assets/screenshots/Phone-5.png';
import Phone5x2 from './assets/screenshots/Phone-5@2x.png';
import Phone6 from './assets/screenshots/Phone-6.png';
import Phone6x2 from './assets/screenshots/Phone-6@2x.png';

import male from './assets/clients/photo-male.jpg';
import malex2 from './assets/clients/photo-male@2x.jpg';
import female from './assets/clients/photo-female.jpg';
import femalex2 from './assets/clients/photo-female@2x.jpg';

export default {
  title: 'MamaParking',
  navbar: {
    links: [
      {
        to: 'welcome',
        label: 'Home',
      },
      {
        to: 'about',
        label: 'About',
      },
      {
        to: 'features',
        label: 'Funzionalità',
      },
      {
        to: 'screens',
        label: 'Anteprima',
      },
      /*{
        to: 'clients',
        label: 'Clienti',
      },
      {
        to: 'support',
        label: 'Supporto',
      },*/
      /*{
        to: 'pricing',
        label: 'Pricing',
      },
      {
        to: 'contact',
        label: 'Contatti',
      },*/
      {
        to: 'newsletter',
        label: 'Contatti',
      },
    ],
    actions: [
      {
        //href: 'http://mypags.app',
        to: 'newsletter',
        label: 'Download',
      },
    ],
  },
  welcome: {
    title: 'La nuova app per mamme che ti semplifica la vita!',
    text: `Scopri i parcheggi rosa più vicino a te su MamaParking, l'app dedicata alle donne incinta e a tutti i genitori.`,
  },
  footer: {
    section1: {
      text: `Mamaparking è un progetto sviluppato da Koent S.r.l. con l\'obiettivo di semplificare la vita di tutti i giorni di chi si prende cura delle future generazioni con amore.`,
    },
    contact: {
      title: 'Contatti',
      sections: [
        {
          icon: MapMarker,
          text: textToMultiline(`Koent S.r.l.\nPiazza IV Novembre, 4\n20124 Milano`),
        },
        {
          icon: Globe,
          text: 'koent.it',
          href: 'https://koent.it'
        },
        /*{
          icon: Envelope,
          text: 'info@mamaparking.app',
        },*/
        {
          icon: Envelope,
          text: 'info@koent.it',
          href: 'mailto:info@koent.it'
        },
      ],
    },
    section3: {
      title: 'Supporto & download',
      text: `Contattaci per ogni necessità o consiglio. Saremo ben lieti di sentire la tua e sviluppare nuove funzionalità insieme.`,
    },
  },
  about: {
    title: 'Perché usare MamaParking?',
    text: `Un parcheggio comodo per le mamme fa la differenza e poterlo trovare in pochi secondi è ancora più comodo!`,
    features: [
      {
        id: 'feature1',
        IconProps: {
          icon: Dna,
          gradient: 'tertiary',
        },
        title: 'Trova parcheggi rosa apposta per te',
        text:
          'MamaParking è semplicissima: indica la posizione in cui ti trovi o dove vuoi andare e scopri i parcheggi rosa vicino a te e alla tua destinazione.',
      },
      {
        id: 'feature2',
        IconProps: {
          icon: Flask,
          gradient: 'primary',
        },
        title: 'Scopri notizie e articoli interessanti',
        text:
          'Trova informazioni su come richiedere il Pass Rosa e articoli interessanti selezionati per te: viaggi, esperienze, ricette, mobilità e altri consigli per genitori e neomamme.',
      },
      {
        id: 'feature3',
        IconProps: {
          icon: Atom,
          gradient: 'quaternary',
        },
        title: 'Aiuta altre mamme',
        text:
          'Scopri nuovi parcheggi rosa e condividili subito con le altre mamme, entra a far parte della community di MamaParkers in continua espansione.',
      },
    ],
  },
  socialLinks: [
    {
      icon: Globe,
      href: 'https://koent.it',
      title: 'Sito Web di Koent',
    },
    /*{
      icon: FacebookF,
      href: 'https://facebook.com/mamaparking.app',
      title: 'Facebook',
    },
    {
      icon: Instagram,
      href: 'https://instagram.com/mamaparking.app',
      title: 'Instagram',
    },
    {
      icon: Youtube,
      href: 'https://koent.it',
      title: 'Sito Internet',
    },*/
  ],
  features: [
    {
      ImageProps: {
        src: firstFuture,
      },
      title: 'Come funziona',
      text:
        'La mappa di MamaParking ti guiderà alla scoperta del parcheggio più vicino.',
      list: [
        {
          IconProps: {
            icon: CheckCircle,
            gradient: 'quaternary',
          },
          title: 'Trova un parcheggio',
          text: 'Apri la mappa, attiva la localizzazione o inserisci l\'indirizzo di destinazione per visualizzare i parcheggi rosa disponibili nelle vicinanze.',
        },
        {
          IconProps: {
            icon: CheckCircle,
            gradient: 'quaternary',
          },
          title: 'Lascia il tuo feedback',
          text: 'Seleziona il parcheggio individuato e lascia un tuo commento o una segnalazione per aiutare la community delle mamme',
        },
        {
          IconProps: {
            icon: CheckCircle,
            gradient: 'quaternary',
          },
          title: 'Inserisci nuovi parcheggi',
          text: 'Dai il tuo contributo fornendo la posizione di nuovi parcheggi rosa non ancora mappati e semplifica la vita anche ale altre famiglie',
        },
      ],
    },
    /*{
      ImageProps: {
        src: secondFuture,
      },
      title: 'Second feature',
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel vehicula dui. Curabitur consectetur eu risus at mollis. Vivamus iaculis vehicula fermentum.',
      list: [
        {
          IconProps: {
            icon: CheckCircle,
            gradient: 'quaternary',
          },
          title: 'Features subheading',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel vehicula dui.',
        },
        {
          IconProps: {
            icon: CheckCircle,
            gradient: 'quaternary',
          },
          title: 'Another features subheading',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel vehicula dui.',
        },
        {
          IconProps: {
            icon: CheckCircle,
            gradient: 'quaternary',
          },
          title: 'And just one more',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel vehicula dui.',
        },
      ],
    },*/
    /*{
      title: 'Another features section',
      text:
        'Ut commodo suscipit neque vitae suscipit. Mauris congue viverra dolor, vel lobortis elit venenatis vel. Nunc non orci ut mi semper aliquet. Nullam vitae tortor faucibus, vehicula lorem in, convallis mi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis vestibulum neque.',
      list: [
        {
          IconProps: {
            icon: Gift,
            gradient: 'primary',
          },
          title: 'Features subheading',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel vehicula dui.',
        },
        {
          IconProps: {
            icon: Coffee,
            gradient: 'tertiary',
          },
          title: 'And just one more',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel vehicula dui.',
        },
        {
          IconProps: {
            icon: Medal,
            gradient: 'secondary',
          },
          title: 'Another features subheading',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel vehicula dui.',
        },
        {
          IconProps: {
            icon: LightbulbAlt,
            gradient: 'quaternary',
          },
          title: 'And just one more',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel vehicula dui.',
        },
      ],
    },*/
    /*{
      ImageProps: {
        src: thirdFuture,
      },
      title: 'More amazing features',
      text:
        'Ut commodo suscipit neque vitae suscipit. Mauris congue viverra dolor, vel lobortis elit venenatis vel. Nunc non orci ut mi semper aliquet. Nullam vitae tortor faucibus, vehicula lorem in, convallis mi.',
      list: [
        {
          title: 'Features subheading',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel vehicula dui.',
        },
        {
          title: 'Another features subheading',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel vehicula dui.',
        },
        {
          title: 'And just one more',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel vehicula dui.',
        },
        {
          title: 'Features subheading',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel vehicula dui.',
        },
        {
          title: 'Another features subheading',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel vehicula dui.',
        },
        {
          title: 'And just one more',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vel vehicula dui.',
        },
      ],
    },*/
  ],
  screenshots: {
    title: 'Anteprima',/*Screenshots,*/
    text:
      'Sfoglia l\'anteprima di MamaParking, l\'app studiata per aiutare le donne in gravidanza e le neo mamme a trovare parcheggi rosa in pochi secondi.',
    images: [
      {
        src: Phone1,
        srcSet: `${Phone1} 1x, ${Phone1x2} 2x`,
        alt: 'Phone 1',
      },
      {
        src: Phone2,
        srcSet: `${Phone2} 1x, ${Phone2x2} 2x`,
        alt: 'Phone 2',
      },
      {
        src: Phone3,
        srcSet: `${Phone3} 1x, ${Phone3x2} 2x`,
        alt: 'Phone 3',
      },
      {
        src: Phone4,
        srcSet: `${Phone4} 1x, ${Phone4x2} 2x`,
        alt: 'Phone 4',
      },
      {
        src: Phone5,
        srcSet: `${Phone5} 1x, ${Phone5x2} 2x`,
        alt: 'Phone 5',
      },
      {
        src: Phone6,
        srcSet: `${Phone6} 1x, ${Phone6x2} 2x`,
        alt: 'Phone 6',
      },
    ],
  },
  clients: {
    title: 'What clients say about us?',
    comments: [
      {
        author: 'Pat Cooper, Big Company SEO',
        text: `Elit officia consectetur mollit occaecat incididunt sunt labore ad est veniam cupidatat tempor. Deserunt veniam nostrud est ad enim labore sit dolore amet enim veniam ipsum. Ullamco cillum ad nulla quis aliquip fugiat veniam incididunt ipsum cupidatat. Lorem qui voluptate ut enim occaecat et ut eu aliquip culpa.`,
        AvatarProps: {
          src: male,
          srcSet: `${male} 1x, ${malex2} 2x`,
        },
      },
      {
        author: 'Orsola Jeroch, Bigger Company CTO',
        text: `Praesent nec commodo arcu. Vivamus convallis pretium malesuada. Aenean feugiat maximus suscipit. Fusce maximus aliquam ipsum, at hendrerit augue aliquet ac. Maecenas scelerisque, massa a fringilla imperdiet, velit purus gravida dolor, et blandit lorem nulla non eros.`,
        AvatarProps: {
          src: female,
          srcSet: `${female} 1x, ${femalex2} 2x`,
        },
      },
    ],
  },
  faq: {
    title: 'Do you have any questions?',
    text:
      'Ut commodo suscipit neque vitae suscipit. Mauris congue viverra dolor, vel lobortis elit venenatis vel. Nunc non orci ut mi semper aliquet. Nullam vitae tortor faucibus, vehicula lorem in, convallis mi.',
    options: [
      {
        title: 'Cras quis dolor auctor mi varius tincidunt?',
        text:
          'Ut commodo suscipit neque vitae suscipit. Mauris congue viverra dolor, vel lobortis elit venenatis vel. Nunc non orci ut mi semper aliquet. Nullam vitae tortor faucibus, vehicula lorem in, convallis mi.',
      },
      {
        title: 'Mauris accumsan dolor et elementum dapibus?',
        text:
          'Ut commodo suscipit neque vitae suscipit. Mauris congue viverra dolor, vel lobortis elit venenatis vel. Nunc non orci ut mi semper aliquet. Nullam vitae tortor faucibus, vehicula lorem in, convallis mi.',
      },
      {
        title: 'Phasellus sollicitudin neque a odio varius, condimentum ultrices risus molestie?',
        text:
          'Ut commodo suscipit neque vitae suscipit. Mauris congue viverra dolor, vel lobortis elit venenatis vel. Nunc non orci ut mi semper aliquet. Nullam vitae tortor faucibus, vehicula lorem in, convallis mi.',
      },
      {
        title: 'Pellentesque fringilla non dolor ut convallis?',
        text:
          'Ut commodo suscipit neque vitae suscipit. Mauris congue viverra dolor, vel lobortis elit venenatis vel. Nunc non orci ut mi semper aliquet. Nullam vitae tortor faucibus, vehicula lorem in, convallis mi.',
      },
      {
        title: 'Integer imperdiet venenatis orci, ac dictum libero semper sit amet?',
        text:
          'Ut commodo suscipit neque vitae suscipit. Mauris congue viverra dolor, vel lobortis elit venenatis vel. Nunc non orci ut mi semper aliquet. Nullam vitae tortor faucibus, vehicula lorem in, convallis mi.',
      },
    ],
  },
  pricing: {
    title: 'Pricing',
    prices: {
      currency: 'USD',
      types: {
        a: 'Monthly',
        b: 'Annually',
      },
      sections: [
        {
          title: 'Beginner',
          price: {
            a: '$0',
            b: '$0',
          },
          features: [
            {
              text: 'Ut commodo suscipit',
              check: true,
            },
            {
              text: 'Mauris congue viverra',
              check: true,
            },
            {
              text: 'Nunc non orci ut mi',
              check: false,
            },
            {
              text: 'Nullam vitae tortor',
              check: false,
            },
            {
              text: 'Lorem ipsum dolor sit',
              check: false,
            },
            {
              text: 'Fusce quis vestibulum',
              check: false,
            },
          ],
          button: {
            text: 'Choose beginner',
            ButtonProps: {
              as: 'a',
              href: 'https://mypags.app',
            },
          },
        },
        {
          title: 'Advanced',
          price: {
            a: '$12',
            b: '$120',
          },
          features: [
            {
              text: 'Ut commodo suscipit',
              check: true,
            },
            {
              text: 'Mauris congue viverra',
              check: true,
            },
            {
              text: 'Nunc non orci ut mi',
              check: true,
            },
            {
              text: 'Nullam vitae tortor',
              check: true,
            },
            {
              text: 'Lorem ipsum dolor sit',
              check: false,
            },
            {
              text: 'Fusce quis vestibulum',
              check: false,
            },
          ],
          button: {
            text: 'Choose advanced',
            ButtonProps: {
              as: 'a',
              href: 'https://mypags.app',
            },
          },
        },
        {
          title: 'Expert',
          price: {
            a: '$24',
            b: '$240',
          },
          features: [
            {
              text: 'Ut commodo suscipit',
              check: true,
            },
            {
              text: 'Mauris congue viverra',
              check: true,
            },
            {
              text: 'Nunc non orci ut mi',
              check: true,
            },
            {
              text: 'Nullam vitae tortor',
              check: true,
            },
            {
              text: 'Lorem ipsum dolor sit',
              check: true,
            },
            {
              text: 'Fusce quis vestibulum',
              check: true,
            },
          ],
          button: {
            text: 'Choose expert',
            ButtonProps: {
              as: 'a',
              href: 'https://mypags.app',
            },
          },
        },
      ],
    },
  },
  contact: {
    title: 'Contattaci',
    text:
      'Ut commodo suscipit neque vitae suscipit. Mauris congue viverra dolor, vel lobortis elit venenatis vel. Nunc non orci ut mi semper aliquet. Nullam vitae tortor faucibus, vehicula lorem in, convallis mi.',
    sendButtonText: 'Invia messaggio',
    successMessage: 'Thank you for creating ticket. We will response as soon as possible.',
    errorMessage: 'An error occurred. Can&apos;t create a ticket now. Please retry later.',
    form: {
      validationSchema: Yup.object({
        name: Yup.string().max(15, 'Devono esserci meno di 15 caratteri').required('Richiesto'),
        email: Yup.string().email('Deve essere una email valida').required('Richiesto'),
        message: Yup.string().min(20, 'Must be at least 20 characters').required('Richiesto'),
      }),
      // eslint-disable-next-line no-undef
      onSubmit: values => window.alert(`Form sent with values ${JSON.stringify(values)}`),
      fields: [
        {
          name: 'name',
          label: 'First Name',
          placeholder: 'Il tuo nome',
          initialValue: '',
        },
        {
          name: 'email',
          placeholder: 'La tua e-mail',
          label: 'E-mail',
          type: 'email',
          initialValue: '',
        },
        {
          name: 'message',
          placeholder: 'Inserisci il tuo messaggio',
          label: 'Message',
          multiline: true,
          initialValue: '',
        },
      ],
    },
  },
  downloadUpdate: {
    title: 'Resta aggiornata',
    text: 'Inserisci la tua email e ti contatteremo appena la app sarà disponibile per il download',
    sendButtonText: 'Registrati',
    successMessage: 'Grazie per esserti registrata. Ti contatteremo appena avremo novità',
    errorMessage: 'Pare che ci sia un errore. Per favore, Riprova più tardi.',
    form: {
      validationSchema: Yup.object({
        email: Yup.string().email('Deve essere una email valida').required('Richiesto'),
        policyCheck: Yup.bool().oneOf([true],'Devi accettare la nostra privacy policy prima di poter fornire la tua mail').required('Richiesto'),
      }),
      // eslint-disable-next-line no-undef
      onSubmit: values => window.alert(`Form sent with values ${JSON.stringify(values)}`),
      fields: [
        {
          name: 'email',
          placeholder: 'Il tuo indirizzo email',
          label: 'E-mail',
          type: 'email',
          initialValue: '',
        },
        {
          name: 'policyCheck',
          placeholder: '',
          label: 'Ho letto, compreso e accettato i termini e condizioni',
          type: 'checkbox',
          initialValue: false,
        },
      ],
    },
  },
  newsletter: {
    title: 'Resta aggiornata',
    text: 'Inserisci la tua email e ti contatteremo appena la app sarà disponibile per il download',
    buttonText: 'Registrati',
    inputPlaceholder: 'Il tuo indirizzo email',
  },
  copyright: textToMultiline`Made with love in Milan by Koent S.r.l.\nPiazza IV Novembre 4, 20124 Milano (MI)\nP.Iva e C.F.: 12020000969 | Cap. soc. €10.000 n.i.v.`,
};
