import React, { useState } from 'react';
import PropTypes, { ReactNodeLike } from 'prop-types';
import classNames from 'classnames';

import { Collapse } from 'react-collapse';

import {
  ErrorMessage,
  InputWrapper,
  Label,
  StyledInput,
  StyledTextarea,
  Wrapper,
} from './styled.components';

interface Props {
  /**
   * Name of the field. It will be used also in label purposes.
   */
   name: string;
   /**
    * Type of input, eg. email, date
    */
   type: string;
   /**
    * Label above input
    */
   label: ReactNodeLike,
   /**
    * Error message
    */
   error: ReactNodeLike,
   /**
    * Should use success state
    */
   success: boolean,
   /**
    * Render as textarea
    */
   multiline: boolean,
   /**
    * Is input touched
    */
   touched: boolean,
   /**
    * Node placed before input component
    */
   prefix: ReactNodeLike,
   /**
    * Node placed after input component
    */
   suffix: ReactNodeLike,
   /**
    * On Focus function
    */
   onFocus: (...args: any[]) => any,
   /**
    * On Blur function
    */
   onBlur: (...args: any[]) => any,
   /**
    * Additional props for wrapper. Eg. styled system props.
    */
   WrapperProps: any,
   /**
    * Additional props for input wrapper. Eg. styled system props.
    */
   InputWrapperProps: any,
   /**
    * Additional props for label.
    */
   LabelProps: any,
   /**
    * Additional props for error message.
    */
   ErrorMessageProps: any,
}
/**
 * Input let users enter and edit text.
 */
const Input: React.FC<Props> = ({
  label,
  name,
  multiline,
  error,
  success,
  touched,
  type,
  onFocus,
  onBlur,
  suffix,
  prefix,
  WrapperProps,
  InputWrapperProps,
  LabelProps,
  ErrorMessageProps,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const InputComponent = multiline ? StyledTextarea : StyledInput;

  const handleFocus = (event: any) => {
    if (onFocus) {
      onFocus(event);
    }

    setFocused(true);
  };

  const handleBlur = (event: any) => {
    if (onBlur) {
      onBlur(event);
    }

    setFocused(false);
  };

  return (
    <Wrapper
      className={classNames({
        focused,
        error: !!error,
        success: success || (!error && touched),
        [type]: !!type,
      })}
      {...WrapperProps}
    >
      {label && (
        <Label htmlFor={name} {...LabelProps}>
          {label}
        </Label>
      )}
      <InputWrapper {...InputWrapperProps}>
        {prefix}
        <InputComponent
          name={name}
          id={name}
          type={type}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...props}
        />
        {suffix}
      </InputWrapper>

      <Collapse isOpened={!!error}>
        <ErrorMessage {...ErrorMessageProps}>{error}</ErrorMessage>
      </Collapse>
    </Wrapper>
  );
};

export default Input;
